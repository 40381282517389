import React from "react";
import './features.css'
function Features(){
    return (
      <section class="py-5 mt-1" id="sobre">
      <div class="linha-branca">
      </div>
      <div class="container pb-5 sobre col-10">
          <div class="dados-sobre row">
              <div class="img-sobre col-md-5 col-lg-5 col-sm-2 col-12">
                  <img src="img/img-sobre.png" alt="img1" class="img-sobre1 img-fluid" />
              </div>
              <div class="text-sobre col-md-7 col-12 text-white">
                  <div class="titulo-caixa text-center">
                      <h1 class="text-center py-4 mb-2">Sobre Nós</h1>
                      <div class="text-center">
                          <p className="text-white">A Cobra Operante é uma empresa fictícia líder no mercado de tecnologia digital. Com uma
                              história de excelência e inovação, nos estabelecemos como uma referência no setor desde
                              o nosso início. Nossa jornada começou com a paixão por soluções criativas e seguras para
                              o mundo online.
                              <br/><br/>
                              Desde então, temos nos dedicado a oferecer serviços de desenvolvimento web, design
                              gráfico e segurança cibernética que atendem às necessidades em constante evolução dos
                              nossos clientes. Ao longo dos anos, construímos uma reputação baseada na qualidade do
                              nosso trabalho e no compromisso com a satisfação dos clientes.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </section>

      );
}
export default Features;